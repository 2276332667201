import { InfiniteScroll } from 'tw-elements';
// Get Any Element html
const feedContainer = document.querySelector('#feedNews');
const spinner = document.querySelector('#spinner');
let isFetching = false;

const loadComponentArticle = (url) => {
    if (isFetching) {
        return;
    }
    var pageActive = parseInt(feedContainer.dataset.page);
    pageActive++;
    // Spinner Visible
    spinner.classList.replace('hidden', 'inline-block');
    const LINK = url + '?page=' + pageActive;

    isFetching = true;

    fetch(LINK)
        .then(response => response.text())
        .then(html => {
            // window.removeEventListener('complete.te.infiniteScroll', loadComponentArticle);
            // Spinner Hidden
            spinner.classList.replace('inline-block', 'hidden');
            // Append html
            feedContainer.insertAdjacentHTML('beforeend', html);
            // Update dataset
            feedContainer.setAttribute('data-page', pageActive);
        })
        .finally(() => {
            isFetching = false;
        });
}
new InfiniteScroll(window);
export default loadComponentArticle;
